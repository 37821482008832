import { IdName } from '@/models/Interfaces';

export enum ChatMessageType {
    IndexUser = 1,
    Provider = 2
}

export enum ChatType {
    Message = 1,
    OrderQuote = 2
}

export enum OrderQuoteRequestedTime {
    Morning = 1,
    Noon,
    Afternoon,
    Evening,
    Night
}

export class ChatMessage {
    id = '';
    createdAt = '';
    message = '';
    type: ChatMessageType = ChatMessageType.Provider;
    isToday = false;
    read = false;
    attachmentPath = '';
    attachmentName = '';
}

export class OrderQuote {
    id = 0;
    name = '';
    productId = '';
    date = '';
    requestedTime = '';
    totalGuests = 0;
    amount = 0;
    currencyId = '';
    comments = '';
    commentsFull = false;
    createdAt = null;
}

export class ChatItem {
    productId = '';
    productName = '';
    providerId = '';
    indexUserId = '';
    indexUserName = '';
    agencyName = '';
    lastMessageDate = '';
    groupName = '';
    unreadedMessages = 0;
    orderQuote: OrderQuote | null = null;
    messages: ChatMessage[] = [];
}

export class ChatFilter {
    chatType: ChatType | null = null;
    productId = '';
    keyword = '';
    pageIndex = 0;
    orderBy = 'Id';
    asc = false;
}

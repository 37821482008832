
import { defineComponent, ref, computed, nextTick, onMounted, onUnmounted } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import swal from 'sweetalert2';
import { ChatMessage, ChatMessageType } from '@/models/ChatQuotes';
import { api } from '@/services/Api';

export default defineComponent({
    name: 'ProductChat',
    props: {
        productId: {
            type: String,
            required: true
        },
        indexUserId: {
            type: String,
            required: true,
            default: () => null
        },
        orderQuoteId: {
            type: Number,
            required: false,
            default: () => null
        }
    },
    setup(props, { emit }) {
        const chatTimerId = ref();
        const chatMessages = ref<ChatMessage[]>([]);
        const { t } = useI18n();

        const msgSending = ref(false);
        const message = ref('');

        const messagedMarked = ref(false);
        const productId = computed(() => props.productId);
        const indexUserId = computed(() => props.indexUserId);
        const orderQuoteId = computed(() => props.orderQuoteId);
        let observer: IntersectionObserver | null = null;
        const fileInput = ref();
        const files = ref<File[]>([]);

        const isToday = (date: string) => {
            const d = moment(date).utc();
            return moment()
                .utc()
                .isSame(d, 'day');
        };

        const filteredChatMessages = computed(() => {
            const results = chatMessages.value.filter(m => m.message || m.attachmentPath);
            const index = results.findIndex(f => isToday(f.createdAt));
            if (index >= 0) results[index].isToday = true;
            return results;
        });

        const trackLastMessage = async () => {
            await nextTick();
            const messagesContainer = document.getElementById('messages-container');
            const lastMessage = messagesContainer?.lastElementChild;

            if (lastMessage) {
                observer?.observe(lastMessage);
            }
        };

        const markQuoteAsRead = async () => {
            await api.MarkChatAsRead(productId.value, orderQuoteId.value, indexUserId.value);
            chatMessages.value.forEach(chat => {
                chat.read = true;
            });
        };

        const markMessagesAsRead = async () => {
            if (filteredChatMessages.value && filteredChatMessages.value.length > 0 && messagedMarked.value === false) {
                try {
                    await api.MarkChatAsRead(productId.value, orderQuoteId.value, indexUserId.value);
                    chatMessages.value.forEach(chat => {
                        chat.read = true;
                    });
                } catch (error) {
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: error as any });
                    return;
                }
                messagedMarked.value = true;
            }
        };

        const scrollMessagesToEnd = async () => {
            await nextTick();
            const container = document?.getElementById('messages-container');

            const lastUnreadedMessage = container?.getElementsByClassName('from-index un-readed');
            if (lastUnreadedMessage?.length) {
                lastUnreadedMessage[0].scrollIntoView({ behavior: 'smooth' });
            } else if (container?.lastElementChild) {
                container.lastElementChild.scrollIntoView({ behavior: 'smooth' });
            }
        };

        let getNextMessages: () => void = () => {
            'funtion template for stupid composition api';
        };

        const getMessages = async (fromId?: string, showLoading = false) => {
            if (showLoading) swal.showLoading();
            const chatResult = await api.ProductChatMessages(productId.value, indexUserId.value, orderQuoteId.value, fromId);
            if (chatResult.data) {
                messagedMarked.value = false;
                if (!fromId) {
                    chatMessages.value = chatResult.data;
                    // Mark Quote message as read
                    if (chatResult.data.length == 1 && chatResult.data[0].message == null) {
                        markQuoteAsRead();
                    }
                } else {
                    chatMessages.value.push(...chatResult.data);
                }

                if (chatResult.data.length > 0) {
                    if (chatResult.data.some(c => c.read === false && c.type === ChatMessageType.IndexUser)) {
                        trackLastMessage();
                    }
                    scrollMessagesToEnd();
                }
            }
            if (showLoading) swal.close();

            if (chatMessages.value.length) {
                clearTimeout(chatTimerId.value);
                chatTimerId.value = setTimeout(getNextMessages, 30 * 1000);
            }
        };

        getNextMessages = async () => {
            let fromId: string | undefined;

            if (chatMessages.value.length > 0) {
                fromId = chatMessages.value[chatMessages.value.length - 1].id;
            }
            await getMessages(fromId);
        };

        getMessages();

        const uploadFile = () => {
            if (fileInput.value && fileInput.value.files.length > 0) {
                files.value = fileInput.value.files;
            }

            scrollMessagesToEnd();
        };

        const removeAllFiles = () => {
            const dt = new DataTransfer();
            fileInput.value.files = dt.files;
            files.value = [];
        };

        const removeFile = (index: number) => {
            const tempFiles: File[] = [];
            const dt = new DataTransfer();
            for (let i = 0; i < files.value.length; i++) {
                const file = files.value[i];
                if (index !== i) {
                    dt.items.add(file);
                    tempFiles.push(file);
                }
            }
            fileInput.value.files = dt.files;
            files.value = tempFiles;
        };

        function dropHandler(ev: any) {
            ev.preventDefault();
            const dt = new DataTransfer();
            fileInput.value.files.forEach((file: File) => {
                dt.items.add(file);
            });

            [...ev.dataTransfer.items].forEach(item => {
                const file = item.getAsFile();
                dt.items.add(file);
            });

            fileInput.value.files = dt.files;
            files.value = fileInput.value.files;
            scrollMessagesToEnd();
        }

        const dragOverHandler = (ev: any) => ev.preventDefault();

        const sendMessage = async () => {
            if ((!files.value || !files.value.length) && (!message.value.trim() || msgSending.value)) {
                return;
            }
            msgSending.value = true;

            swal.showLoading();
            const fileTypes: string[] = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf', 'png', 'ppt', 'jpg', 'gif', 'mp3', 'mp4'];
            const fd = new FormData();
            let errorFileTypeSize = false;
            files.value.forEach((file: any) => {
                const fileExt = file.name.split('.').pop();
                if (!fileTypes.includes(fileExt)) {
                    swal.fire({
                        icon: 'error',
                        text: 'Error file type'
                    });
                    errorFileTypeSize = true;
                    return;
                }

                if (file.size > 5242880) {
                    swal.fire({
                        icon: 'error',
                        text: 'File size should be less or equal to 5 MB'
                    });
                    errorFileTypeSize = true;
                    return;
                }
                fd.append('files', file, file.name);
            });

            if (errorFileTypeSize) {
                removeAllFiles();
                errorFileTypeSize = false;
                msgSending.value = false;
                return;
            }

            try {
                fd.append('message', message.value);
                fd.append('orderQuoteId', JSON.stringify(orderQuoteId.value ?? 0));
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };
                const apiResult = await api.SendChatMessageFiles(config, fd, productId.value, indexUserId.value);
                if (apiResult.hasValidationError) {
                    const message = apiResult
                        .validationErrorsList()
                        ?.map(x => `Field '${x.key}': ${x.msg}`)
                        ?.join('\n');
                    swal.fire({
                        icon: 'error',
                        text: message
                    });
                    msgSending.value = false;
                    return;
                }
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    msgSending.value = false;
                    return;
                }
            } catch (error) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: error as any });
                msgSending.value = false;
                return;
            }

            removeAllFiles();
            message.value = '';
            await getNextMessages();
            msgSending.value = false;
            emit('send-message');
            swal.close();
        };

        const formatChatDate = (date: string) => {
            const d = moment(date).utc();
            return moment()
                .utc()
                .isSame(d, 'day')
                ? d.format('hh:mm A')
                : d.format('DD/MM/YYYY hh:mm A');
        };

        const callback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    markMessagesAsRead();
                }
            });
        };

        onMounted(() => {
            observer = new IntersectionObserver(callback, {
                root: document.getElementById('messages-container'),
                threshold: [1] // If 100% of the element is in the screen, we count it!
                // Can change the thresholds based on your needs. The default is 0 - it'll run only when the element first comes into view
            });
        });

        onUnmounted(() => {
            clearTimeout(chatTimerId.value);
        });

        return {
            message,
            sendMessage,
            formatChatDate,
            chatMessages,
            ChatMessageType,
            filteredChatMessages,
            isToday,
            uploadFile,
            fileInput,
            files,
            removeFile,
            dropHandler,
            dragOverHandler
        };
    }
});

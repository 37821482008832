
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true
        },
        showBack: {
            type: Boolean,
            default: true
        }
    },
    async setup(props, { emit }) {
        function back() {
            emit('back');
        }
        return { back };
    }
});

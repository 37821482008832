
import { defineComponent, PropType, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';
import { ChatType } from '@/models/ChatQuotes';
import { IdName } from '@/models/Interfaces';

export default defineComponent({
    components: {
        Multiselect,
        OverlayModal
    },
    name: 'ChatQuotesFilterModal',
    props: {
        products: {
            type: Array as PropType<IdName[]>,
            default: () => []
        },
        chatTypes: {
            type: Array as PropType<IdName[]>,
            default: () => []
        },
        showProduct: {
            type: Boolean,
            default: true
        }
    },
    emits: ['search', 'resetAll'],
    data() {
        return {};
    },
    async setup(props, { emit }) {
        const { t } = useI18n();
        const chatType = ref<ChatType | null>(null);
        const productId = ref('');
        const isOpen = ref(false);
        function open(productIdProp: string, chatTypeProp: ChatType | null) {
            productId.value = productIdProp;
            chatType.value = chatTypeProp;
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        function search() {
            emit('search', { productId: productId.value, chatType: chatType.value });
            isOpen.value = false;
        }
        function resetAll() {
            emit('resetAll');
            isOpen.value = false;
        }
        return {
            isOpen,
            open,
            close,
            search,
            resetAll,
            chatType,
            productId
        };
    }
});
